import React, { useEffect, useRef, useState } from "react";
import './App.css';

const Goose = () => {
  const [position, setPosition] = useState(0);
  const speed = 2;

  useEffect(() => {
    const moveGoose = () => {
      setPosition((prev) => (prev > window.innerWidth ? 0 : prev + speed));
    };
    const interval = setInterval(moveGoose, 20);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="goose" style={{ left: position }}>
      🪿
    </div>
  );
};

const CopyButton = ({ text, link }) => {
  const [copied, setCopied] = useState(false);

  const copyToClipboard = (textToCopy) => {
    navigator.clipboard.writeText(textToCopy)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      })
      .catch((err) => {
        console.error('Failed to copy text: ', err);
      });
  };

  return (
    <button onClick={() => copyToClipboard(text)}>
      {copied ? '☑️' : '📄'}
    </button>
  );
};

const Status = () => {
  const running = "operational";
  const down = "down";
  const [status, setStatus] = useState(running);
  const [statusColor, setStatusColor] = useState('var(--status-up)');

  useEffect(() => {
    const checkStatus = async () => {
      try {
        const response = await fetch('/api/status');
        const data = await response.json();
        if (data.data.status === 'online') {
          setStatus(running);
          setStatusColor('var(--status-up)');
        } else {
          setStatus(down);
          setStatusColor('var(--status-down)');
        }
      } catch (error) {
        setStatus(down);
        setStatusColor('var(--status-down)');
      }
    };

    checkStatus();
  }, []);

  return (
    <h2 className="headpad" style={{ backgroundColor: statusColor, borderRadius: "3px", color: "white", margin: "30px 0"}}>
      API is {status}
      <span style={{ float: "right" }}></span>
    </h2>
  );
};

const ScrollComponent = ({ children }) => {
  return (
    <div className="scroll-container">
      {children.map((child, index) => (
        <div key={index} className="scroll-block">
          {child}
        </div>
      ))}
    </div>
  );
};

const SomeComponent = ({ title, content }) => {
  return (
    <div>
      <h2>{title}</h2>
      <p>{content}</p>
    </div>
  );
};

function HelloWorld() {
  const [globe, setGlobe] = useState('🌍');

  useEffect(() => {
    const interval = setInterval(() => {
      setGlobe((prevGlobe) => (prevGlobe === '🌍' ? '🌎' : '🌍'));
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="container">
      <div className="globe">{globe}</div>
    </div>
  );
}

const PyTorch = () => {
  return (
      <img
        src="https://t2.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=https://pytorch.org&size=180"
        className="logo"
      />
);
}

const LightGBM = () => {
  return (
      <img
        src="https://t2.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=https://lightgbm.readthedocs.io/en/stable/&size=180"
        className="logo"
      />
);
}

const Cplusplus = () => {
  return (
      <img
        src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/18/ISO_C%2B%2B_Logo.svg/213px-ISO_C%2B%2B_Logo.svg.png"
        className="logo"
      />
);
}

const CUDA = () => {
  return (
      <img
        src="https://upload.wikimedia.org/wikipedia/en/b/b9/Nvidia_CUDA_Logo.jpg"
        className="logo"
      />
);
}

const C = () => {
  return (
      <img
        src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/35/The_C_Programming_Language_logo.svg/230px-The_C_Programming_Language_logo.svg.png"
        className="logo"
      />
);
}

const CMake = () => {
  return (
      <img
        src="https://upload.wikimedia.org/wikipedia/commons/thumb/e/ef/CMake_logo.svg/240px-CMake_logo.svg.png"
        className="logo"
      />
);
}


const Python = () => {
  return (
      <img
        src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/c3/Python-logo-notext.svg/219px-Python-logo-notext.svg.png"
        className="logo"
        style={{  transform: "translateY(6.3px)" , height: "1.2em"}}
      />
);
}

const React_ = () => {
  return (
      <img
        src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/30/React_Logo_SVG.svg/260px-React_Logo_SVG.svg.png"
        className="logo"
      />
);
}

const GitHub = () => {
  return (
    <img
      src="https://upload.wikimedia.org/wikipedia/commons/9/91/Octicons-mark-github.svg"
      className="logo"
      alt="GitHub logo"
    />
  );
};

const Kaggle = () => {
  return (
    <img
      style={{transform: "translateX(3px) translateY(2px)"}}
      src="https://cdn4.iconfinder.com/data/icons/logos-and-brands/512/189_Kaggle_logo_logos-512.png"
      className="logo"
      alt="Kaggle logo"
    />
  );
};

const LeetCode = () => {
  return (
    <img
      style={{transform: "translateY(4px)"}}
      src="https://upload.wikimedia.org/wikipedia/commons/1/19/LeetCode_logo_black.png"
      className="logo"
      alt="LeetCode logo"
    />
  );
};

const NewWindow = () => {
  return (
    <svg width="0.5625rem" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 9L9 1M9 1H2.5M9 1V7.22222" stroke="currentColor" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"></path></svg>
  );
};

const TryMeButton = ({ apiText, text }) => {
  const [output, setOutput] = useState("");
  const [buttonText, setButtonText] = useState(apiText);

  const handleApiCall = async () => {
    try {
      const response = await fetch(text);
      const data = await response.json();
      setOutput(JSON.stringify(data, null, 2));
      setButtonText("Success!");
    } catch (error) {
      setOutput("Error: " + error.message);
      setButtonText("Error!");
    }
    // setTimeout(() => setOutput(""), 5000);
    setTimeout(() => setButtonText(apiText), 2000);
  };

  return (
    <div className="try-me-container">
      <button style={{margin: '10px'}} onClick={handleApiCall} className="try-me-button">
        {buttonText}
      </button>
      {output && (
        <div className="api-output">
          {output}
        </div>
      )}
    </div>
  );
};

const CopyToClipboard = ({ text }) => {
  const [copyButtonColor, setCopyButtonColor] = useState("#555");

  function CopyIcon() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill={copyButtonColor}>
        <path d="M360-240q-33 0-56.5-23.5T280-320v-480q0-33 23.5-56.5T360-880h360q33 0 56.5 23.5T800-800v480q0 33-23.5 56.5T720-240H360Zm0-80h360v-480H360v480ZM200-80q-33 0-56.5-23.5T120-160v-560h80v560h440v80H200Zm160-240v-480 480Z" />
      </svg>
    );
  }

  function CheckIcon() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill={copyButtonColor}>
        <path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z" />
      </svg>
    );
  }

  const [buttonText, setButtonText] = useState(<><CopyIcon /> Copy</>);

  const copyText = () => {
    navigator.clipboard.writeText(text);
    setButtonText(
      <span style={{ position: "relative", display: "inline-block" }}>
        <CheckIcon />
        <span>Copied!</span>
      </span>
    );
    setTimeout(() => setButtonText(<><CopyIcon /> Copy</>), 2000);
  };

  return (
    <div className="copy-container">
      <code>{text}</code>
      <button onClick={copyText} className="copy-button">
        {buttonText}
      </button>
    </div>
  );
};

function OpenSourceSection() {
  return (
    <>
      <h2 className="headpad">Open source</h2>
      <div>
        <p><a href="https://github.com/yrmo/cudagrad">cudagrad</a></p>
        <p><CUDA /><Cplusplus /><CMake /><Python /></p>
        <p>CUDA C++ strided float tensor automatic differentiation engine with Python bindings</p>
      </div>
      <p style={{textAlign: "center", margin: "0 auto"}}><svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#e8eaed"><path d="M160-440v-80h640v80H160Z"/></svg></p>
      <div>
        <p><a href="https://github.com/yrmo/engine.c">engine.c</a></p>
        <p><C /><Python /></p>
        <p>Reimplementation of Karpathy's scalar-valued autograd engine as a Python C extension (9x speedup)</p>
      </div>
    </>
  );
}


function KaggleNotebooksSection() {
  return (
    <>
      <h2 className="headpad">Kaggle notebooks</h2>
      <div>
        <table style={{marginTop: '10px', marginBottom: '10px'}}>
          <thead>
            <tr>
              <th></th>
              <th style={{ textAlign: 'left' }}>Competition</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
          <tr>
              <td><LightGBM/></td>
              <td><a href="https://www.kaggle.com/code/yrmoore/single-lightgbm-model">Playground Series S4E8</a></td>
              <td>Top 25% LightGBM model, overfit public leaderboard</td>
            </tr>
            <tr>
              <td><PyTorch/></td>
              <td><a href="https://www.kaggle.com/code/yrmoore/pytorch-mnist-cnn">Digit Recognizer</a></td>
              <td>0.99+ MNIST</td>
            </tr>
            <tr>
              <td><PyTorch/></td>
              <td><a href="https://www.kaggle.com/code/yrmoore/pytorch-regression">House Prices Advanced Regression Techniques</a></td>
              <td>Categorical data, tricky feature engineering</td>
            </tr>
            <tr>
              <td><PyTorch/></td>
              <td><a href="https://www.kaggle.com/code/yrmoore/simple-pytorch-mlp">Titanic</a></td>
              <td>0.80143 with a very tiny model</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
}

function AboutMeSection() {
  return (
    <>
      <h2 className="headpad">About me</h2>
      <p style={{paddingTop: "0px", marginTop: "0px"}}>
        I've been interested in Python C/C++ extensions recently. I'm trying to win Kaggle competitions. I work as a <s>research assistant</s> <s>data analyst</s> DBA @ <s>Royal Military College of Canada</s> <s>Queen's University</s> Sun Life.
      </p>
    </>
  );
}

function BorderedSection({ children, className = "" }) {
  return (
    <div className={`bordered-section ${className}`}>
      <div className="bordered-section-content">
        {children}
      </div>
    </div>
  );
}

// Blog Post Component (Hello)
function Hello() {
  const iframeRef = useRef(null);

  const handleIframeLoad = () => {
    const iframe = iframeRef.current;
    if (iframe) {
      const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
      const styleElement = iframeDoc.createElement("style");
      styleElement.textContent = `
      pre {
        white-space: pre !important;
      }
      `;
      iframeDoc.head.appendChild(styleElement);
    }
  };

  return (
    <>
      <div className="jupyter-container">
        <iframe
          ref={iframeRef}
          src="/_hello_world.html"
          title="Notebook"
          onLoad={handleIframeLoad}
          style={{ width: "100%", height: "100vh", border: "none" }}
        ></iframe>
      </div>
    </>
  );
}

// Home Component (Content shown on Home Page)
function Home({ navigate }) {
  return (
    <BorderedSection>
    <div>
      <h2>Blog posts</h2>
      <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
        <li>
          <p><a className="link-left" href="/hello-world" onClick={(e) => {
            e.preventDefault(); // Prevent the default link behavior (page reload)
            navigate('/hello-world'); // Manually navigate to /hello
          }}>
            Sep 19, 2024 ⋅ Hello, World!
          </a></p>
        </li>
      </ul>
    </div>
    </BorderedSection>
  );
}

function UploadNotebook({ fileType }) {
  const [file, setFile] = useState(null);
  const [htmlFileUrl, setHtmlFileUrl] = useState("");
  const [notebookName, setNotebookName] = useState("");

  const handleFileChange = async (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);

    if (selectedFile) {
      const nameWithoutExtension = selectedFile.name.replace(".ipynb", "");
      setNotebookName(nameWithoutExtension);

      // Automatically trigger file upload after selection
      await handleUpload(selectedFile);
    }
  };

  const handleUpload = async (fileToUpload) => {
    const formData = new FormData();
    formData.append("file", fileToUpload);

    try {
      const response = await fetch(`https://yrmo.ca/api/convert/ipynb/?to=${fileType}`, {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        throw new Error("File upload failed");
      }

      const blob = await response.blob();
  
      const htmlFileUrl = URL.createObjectURL(blob);
      console.log(htmlFileUrl);
      setHtmlFileUrl(htmlFileUrl);
    } catch (error) {
      console.error("Error uploading the file", error);
    }
  };

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center' , flexDirection: 'row'}}>
      {!htmlFileUrl && <label className="custom-file-upload" style={{padding:"10px", paddingTop:"1px", paddingLeft:"13px", border: "2px dashed var(--border-color)"}}>
            <input type="file" className="try-me-button" onChange={handleFileChange} accept=".ipynb" />
            {`/api/convert/ipynb/?to=${fileType}`}<svg style={{transform: "translateY(5px)", marginLeft: "2px"}} xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#555"><path d="M440-320v-326L336-542l-56-58 200-200 200 200-56 58-104-104v326h-80ZM240-160q-33 0-56.5-23.5T160-240v-120h80v120h480v-120h80v120q0 33-23.5 56.5T720-160H240Z"/></svg>
        </label>}
        {htmlFileUrl && (
          <div style={{margin: '10px', padding:"10px", paddingTop:"1px", paddingLeft:"13px", border: "2px solid var(--border-color)"}}>
            <a href={htmlFileUrl} download={`${notebookName}.${fileType}`}>
              Download converted notebook
              <button style={{border: '0px', padding: '0px'}}><svg style={{transform: "translateY(5px)", marginLeft: "2px"}} xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="rgb(85, 85, 85)"><path d="M480-320 280-520l56-58 104 104v-326h80v326l104-104 56 58-200 200ZM240-160q-33 0-56.5-23.5T160-240v-120h80v120h480v-120h80v120q0 33-23.5 56.5T720-160H240Z"/></svg></button>
            </a>
          </div>
        )}
      </div>
      {htmlFileUrl && (
        <div>
          <p>Refresh the page to convert another notebook.</p>
        </div>  
      )}
    </>
  );
}

const KaggleRankDiagram = () => {
  // Updated data for the number of members in each rank
  const data = {
    Grandmaster: 530,      // Kaggle Grandmaster status
    Master: 2745,          // Kaggle Master status
    Expert: 20000,         // Approximate number of Experts
    Contributor: 500000,   // Approximate number of Contributors
    Novice: 18500000 - 530 - 2745 - 20000 - 500000 // Remaining accounts are Novice
  };

  // Colors for the diagram
  const colors = {
    Grandmaster: '#FFD700', // Gold
    Master: '#C0C0C0', // Silver
    Expert: '#CD7F32', // Bronze
    Contributor: '#00A6FF', // Blue
    Novice: '#A0A0A0', // Grey
  };

  return (
    <div style={{ fontFamily: 'Arial, sans-serif', padding: '20px' }}>
      <h2>Kaggle Members by Rank</h2>
      <p>
        As of May 28, 2024, out of 18.5 million Kaggle accounts, 2,745 have achieved
        Kaggle Master status and 530 have achieved Kaggle Grandmaster status.
      </p>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '0 auto' }}>
        {Object.keys(data).map((rank) => (
          <div key={rank} style={{ textAlign: 'center', margin: '0 10px' }}>
            <div
              style={{
                // width: '100px',
                height: `${Math.log(data[rank]) * 10}px`, // Log scale for better visualization
                backgroundColor: colors[rank],
                marginBottom: '10px',
              }}
            />
            <span>{rank}</span>
            <div>{data[rank].toLocaleString()} members</div>
          </div>
        ))}
      </div>
    </div>
  );
};

function getFormattedTime() {
  const now = new Date();
  const options = {
    timeZone: 'Etc/GMT+4',
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
  };
  const formatter = new Intl.DateTimeFormat([], options);
  const formattedTime = formatter.format(now);

  return formattedTime;
}

function App() {
  const [currentPage, setCurrentPage] = useState(window.location.pathname);
  const [fileType, setFileType] = useState("html");
  const [localTime, setLocalTime] = useState(getFormattedTime());

  useEffect(() => {
    const interval = setInterval(() => {
      setLocalTime(getFormattedTime());
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const navigate = (path) => {
    window.history.pushState({}, '', path);
    setCurrentPage(path);
  };

  useEffect(() => {
    const handlePopState = () => {
      setCurrentPage(window.location.pathname);
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  useEffect(() => {
    setCurrentPage(window.location.pathname);
  }, []);

  const handleFileTypeChange = (e) => {
    setFileType(e.target.value);
  };

  const renderContent = () => {
    if (currentPage === '/hello-world') {
      return (
        <>
          <Hello />
        </>
      );
    } else {
      return (
          <>
          <>
            {/* <BorderedSection>
              <KaggleRankDiagram/>
              </BorderedSection> */}

            <BorderedSection>
              <AboutMeSection />
            </BorderedSection>

            <Home navigate={navigate} />

            <BorderedSection>
              <OpenSourceSection />
            </BorderedSection>

            <BorderedSection>
              <KaggleNotebooksSection />
            </BorderedSection>

            <BorderedSection>
              <Status />
              <div>
                {/* <p>Click the buttons to play with the API:</p>
                <TryMeButton apiText="/api/status" text="https://yrmo.ca/api/status" />
                <TryMeButton apiText="/api/model/xor/predict?a=0&b=1" text='https://yrmo.ca/api/model/xor/predict?a=0&b=1' /> */}
                <p>Convert notebook to 
                  <select value={fileType} onChange={handleFileTypeChange} style={{ marginLeft: '10px' }}>
                    <option value="html"><code>.html</code></option>
                    <option value="py"><code>.py</code></option>
                  </select>:
                </p>
                <div>
                  <UploadNotebook fileType={fileType}/>
                </div>
                {/* <CopyToClipboard text="curl https://yrmo.ca/api/ping" />
                <CopyToClipboard text='curl "https://yrmo.ca/api/model/xor?a=0&b=1"' /> */}
              </div>
            </BorderedSection>
          </>
        </>
      );
    }
  };

  return (
    <div className="App">
      <header
        style={{
          backgroundColor: '#3b69d2',
          padding: '30px',
          paddingTop: '30px',
          // borderRadius: '10px',
          display: 'flex',       
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div style={{ margin: "0 auto",display: 'flex', alignItems: 'center'}}>
          <div style={{  display: 'flex', alignItems: 'center'}}>
            {/* Conditionally render the arrow button if not on the home page */}
            {currentPage !== '/' && (
              <button 
                style={{
                  backgroundColor: 'transparent',
                  color: '#fff',
                  border: 'none',
                  fontSize: '14px',
                  cursor: 'pointer',
                  padding: '10px',
                  outline: 'none',
                  textDecoration: 'none',
                }} 
                onMouseOver={(e) => e.currentTarget.style.color = '#fff'}
                onMouseOut={(e) => e.currentTarget.style.color = '#fff'}
                onClick={() => navigate('/')}
              >
                ↩<br/><span>Back</span>
              </button>
            )}
            <h1 style={{ margin: '0', color: '#fff', marginLeft: currentPage !== '/' ? '10px' : '0' }}>Ryan Moore</h1> {/* Adjust text color to white */}
          </div>
          
          {/* Goose image on the right */}
          <img 
            src="https://media.tenor.com/6mykUNjKTUQAAAAM/goose-goos.gif" 
            alt="Goose GIF"
            style={{ height: '75px'}}  // Adjust size and spacing
          />
        </div>
      </header>
      {renderContent()}
      {/* <div> */}
      {/* <BorderedSection  className="footer-section"> */}
      <footer
        style={{
          
          backgroundColor: '#f9f9f9',
          margin: "0 auto",
          padding: '30px',
          paddingTop: '0px',
          display: 'flex',       
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {/* <h3>My links</h3> */}
        <div className="jank" > {/* backgroundColor: '#f9f9f9' */}
        <ul >
          <li style={{margin: '5px'}}>
            Made with <a href="https://github.com/facebook/react">
              React
            </a> <React_/>
          </li>
          <li style={{padding: '5px'}}>
            <a target="_blank" rel="noopener noreferrer" href="https://github.com/yrmo">GitHub</a> <NewWindow/>
          </li>
          <li style={{padding: '5px'}}>
            <a target="_blank" rel="noopener noreferrer" href="https://www.kaggle.com/yrmoore/">Kaggle</a> <NewWindow/>
          </li>
          <li style={{padding: '5px'}}>
            <a target="_blank" rel="noopener noreferrer" href="https://leetcode.com/u/yrmo/">LeetCode</a> <NewWindow/>
          </li>
          <li style={{padding: '5px'}}>
            <a href="mailto:ryanm.inbox@gmail.com?subject=You're%20awesome%20and%20I%20had%20to%20tell%20you">
              Contact me
            </a> <svg style={{transform: "translateY(3px)"}} xmlns="http://www.w3.org/2000/svg" height="15px" viewBox="0 -960 960 960" width="15px" fill="#555"><path d="M160-160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v480q0 33-23.5 56.5T800-160H160Zm320-280L160-640v400h640v-400L480-440Zm0-80 320-200H160l320 200ZM160-640v-80 480-400Z"/></svg>
          </li>
        </ul>
        </div>
      </footer>
    </div>
  );
}


export default App;
